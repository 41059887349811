import { useState } from "react"
import { createPortal } from "react-dom"

import { HamburgerMenuIcon } from "@radix-ui/react-icons"
import { Button, Link as RadixLink } from "@radix-ui/themes"

type MobileSidenavProps = {
  items: { name: string; href: string }[]
}

const MobileSidenav = ({ items }: MobileSidenavProps) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Button
        variant="ghost"
        style={{ margin: 0 }}
        size="4"
        onClick={() => {
          setOpen((prev) => {
            if (prev) {
              document.body.style.overflow = ""
            } else {
              document.body.style.overflow = "hidden"
            }

            return !prev
          })
        }}
      >
        <HamburgerMenuIcon />
      </Button>
      {open
        ? createPortal(
            <div
              className="mobile"
              style={{
                pointerEvents: "auto",
                position: "absolute",
                top: 57,
                left: 0,
                paddingBottom: 57,
                padding: 16,
                width: "100vw",
                height: "100vh",
                maxWidth: "100vw",
                maxHeight: "100vh",
                backgroundColor: "var(--color-background)",
                zIndex: 1000,
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                gap: 12,
                alignItems: "stretch",
              }}
            >
              <RadixLink href="/dashboard">
                <Button variant="soft" size="3" style={{ width: "100%" }}>
                  Go to dashboard
                </Button>
              </RadixLink>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                  paddingTop: 8,
                  alignItems: "start",
                }}
              >
                <h3>Documentation</h3>
                {items.map((item) => (
                  <RadixLink key={item.href} href={item.href}>
                    <Button variant="ghost" size="3" style={{ width: "100%" }}>
                      {item.name}
                    </Button>
                  </RadixLink>
                ))}
              </div>
            </div>,
            document.getElementsByClassName("radix-themes")[0],
          )
        : null}
    </>
  )
}

export { MobileSidenav }
